import { useEffect, useState } from 'react'
import Faq from 'react-faq-component';

import { fetchPage } from '../../utils/Utils';

import styles from '../../pages/Home/Home.module.css';

const config = {
  animate: true,
  openOnload: 0,
  expandIcon: '+',
  collapseIcon: '-',
};

const FaqSection = () => {
  const [page, setPageData] = useState(null);

  useEffect(() => {
    const getNavigation = async () => {
      try {
        const pageData = await fetchPage('faqs-block');
        setPageData(pageData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    getNavigation();
  }, []
  );

  const formateData = () => {
    let rows = { rows: [] };
    if (!page?.custom_fields) return;

    for (let key in page?.custom_fields) {
      if (key.startsWith('faq_question_') && !key.includes('_answer')) {
        const number = key.split('_')[2];
        const answerKey = `faq_question_${number}_answer`;

        rows.rows.push({
          title: page?.custom_fields[key],
          content: page?.custom_fields[answerKey],
        });
      }
    }
    return rows;
  };

  return (
    <section id="faqs" className={styles['faq-section']}>
      <Faq data={formateData()} config={config} />
    </section>
  )
}

export default FaqSection