import axios from 'axios';

import { BASE_WP_URL } from './Constants';

export const fetchPosts = async (params) => {
  try {
    const res = await axios.get(`${BASE_WP_URL}/wp-json/wp/v2/posts`, params);
    return res.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
    throw error;
  }
}

export const sendEmail = async (type, formData) => {
  const url = type === 'register' ? 'submit-form-reg/' : type === 'vacancy' ? 'submit-form-vacancy/' : 'submit-form-contact/';

  try {
    const response = await fetch(`${BASE_WP_URL}/wp-json/custom/v1/${url}`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      console.log('The form has been sent successfully');
      return true;
    } else {
      console.error('An error occurred when submitting the form.');
      return false;
    }
  } catch (error) {
    console.error('Error:', error);
    return false;
  }
}

export const fetchCatPosts = async (slug) => {
  try {
    const resCategory = await axios.get(`${BASE_WP_URL}/wp-json/wp/v2/categories`, {
      params: { slug: slug }
    });

    if (resCategory.data.length === 0) {
      console.error('Category not found');
    }

    const categoryId = resCategory.data[0].id;
    const res = await axios.get(`${BASE_WP_URL}/wp-json/wp/v2/posts`, {
      params: { categories: categoryId }
    });
    return res.data;
  } catch (error) {
    console.error('Error fetching posts:', error);
  }
}

export const fetchNav = async () => {
  try {
    const res = await axios.get(`${BASE_WP_URL}/wp-json/wp/v2/navigation`);
    const parser = new DOMParser();
    const doc = parser.parseFromString(res.data[0].content.rendered, 'text/html');
    const liElements = doc.querySelectorAll('li.wp-block-navigation-item');
    // const ulElement = doc.querySelector('ul.wp-block-page-list'); // for <ul>

    if (liElements) {
      let NavObj = [];

      liElements.forEach(li => {
        const link = li.querySelector('a');
        if (link) {
          NavObj.push({
            href: link.getAttribute('href'),
            label: link.textContent.trim(),
          });
        }
      });
      return NavObj;

    } else {
      console.error('No elements found.');
    }
  } catch (error) {
    console.error('Error fetching nav:', error);
    throw error;
  }
}

export const fetchPage = async (slug) => {
  try {
    const response = await axios.get(`${BASE_WP_URL}/wp-json/wp/v2/pages?slug=${slug}`);
    if (response.data && response.data[0].custom_fields) {
      return response.data[0];
    }
  } catch (error) {
    console.error('Error fetching data from WordPress:', error);
  }
}

export const getPostDate = (post) => {
  if (post.date) {
    const dateObj = new Date(post.date);
    const formatedDate = dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    return formatedDate;
  }
}

export const validateForm = (values) => {
  let errors = {};

  if (!values.firstName.trim()) {
    errors.firstName = 'Name is required';
  } else if (values.firstName.length < 3) {
    errors.firstName = 'Name must be at least 3 characters';
  } else if (/\d/.test(values.firstName)) {
    errors.firstName = 'Name cannot contain numbers';
  }

  if (!values.lastName.trim()) {
    errors.lastName = 'Last name is required';
  } else if (values.lastName.length < 3) {
    errors.lastName = 'Last name must be at least 3 characters';
  } else if (/\d/.test(values.lastName)) {
    errors.lastName = 'Last name cannot contain numbers';
  }

  if (!values.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = 'Email is not valid';
  }

  return errors;
};

export const validateRegForm = (values, formType) => {
  let errors = {};

  if (formType === 'register') {
    // if (!values?.password?.trim()) {
    //   errors.password = 'Password is required';
    // } else if (values.password.length < 3) {
    //   errors.password = 'Password must be at least 3 characters';
    // }

    // if (!values.confirmPassword.trim()) {
    //   errors.confirmPassword = 'Confirm Password is required';
    // } else if (values.confirmPassword.length < 3 || values.confirmPassword !== values.password) {
    //   errors.confirmPassword = 'Confirm password must be at least 3 characters and match the password';
    // }
  } else {
    if (!values.firstName.trim()) {
      errors.firstName = 'Name is required';
    } else if (values.firstName.length < 3) {
      errors.firstName = 'Name must be at least 3 characters';
    } else if (/\d/.test(values.firstName)) {
      errors.firstName = 'Name cannot contain numbers';
    }
  }

  if (values.phone) {
    const regex = /^\d{10,15}$/;
    if (!regex.test(values.phone)) {
      errors.phone = 'Phone must be at least 10 characters';
    }
  }

  if (!values.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
    errors.email = 'Email is not valid';
  }

  return errors;
};

export function parseCareerContent (renderedContent) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(renderedContent, 'text/html');

  const result = [];
  let currentSection = null;
  const groups = doc.querySelectorAll('.wp-block-group');

  groups.forEach((group) => {
    const title = group.querySelector('.wp-block-heading');
    const content = Array.from(group.children).filter(child => child.tagName === 'P' || child.tagName === 'UL' || child.tagName === 'DIV' || child.tagName === 'A').map(child => child.outerHTML).join('');

    if (currentSection) {
      result.push(currentSection);
    }
    currentSection = {
      title: title.textContent,
      content: content
    };
  });

  if (currentSection) {
    result.push(currentSection);
  }

  return result;
}