import { useEffect, useRef } from 'react'

import { MouseCursor } from '../../utils/Cursor';

import style from './Cursor.module.css';

const Cursor = () => {
  const line1 = useRef();
  const line2 = useRef();

  useEffect(() => {
    MouseCursor(line1.current, line2.current);
  }, []);

  return (
    <>
      <div id="line1" ref={line1} className={style['cursor-line']}></div>
      <div id="line2" ref={line2} className={style['cursor-line']}></div>
    </>
  )
}

export default Cursor;