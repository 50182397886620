import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';

import PostBlock from '../../components/Blog/PostBlock/PostBlock';

import { fetchCatPosts } from '../../utils/Utils';

import styles from './News.module.css';

function News () {
  const [posts, setPosts] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const slug = location.pathname.replace(/^\/+|\/+$/g, '');
    const fetchData = async () => {
      try {
        const postsData = await fetchCatPosts(slug);
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchData();
  }, [location.pathname]);

  return (
    <>
      {posts ? (
        <div className={styles['news-grid']}>
          {posts?.length > 0 && posts.map((item) => (
            <PostBlock post={item} key={item.id} />
          ))}
        </div>) : (
        <p className='loading'></p>
      )}
    </>
  )
}

export default News