import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';

import Accordion from '../../components/Accordion/Accordion';
import PopupForm from '../../components/PopupForm/PopupForm';

import { fetchPage, parseCareerContent } from '../../utils/Utils';

import styles from './Career.module.css';

const Career = () => {
  const [careerData, setCareerData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [formType, setFormType] = useState('vacancy');
  const location = useLocation();
  let vacancy = useRef('');

  const menuHandler = () => {
    setIsActive(!isActive);
  }

  const popupHandle = (vacancyName) => {
    setFormType('vacancy');
    setIsPopupOpen(!isPopupOpen);
    vacancy.current = vacancyName;
    if (isActive) menuHandler();
  };

  useEffect(() => {
    const dataPageAttribute = location.pathname.replace(/^\/+|\/+$/g, '');
    const getNavigation = async () => {
      try {
        const pageData = await fetchPage(dataPageAttribute);
        setPageData(pageData)
        const parsedData = parseCareerContent(pageData?.content?.rendered);
        setCareerData(parsedData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    if (dataPageAttribute) {
      getNavigation();
    } else {
      console.error('data-current-page attribute not found on body');
    }
  }, []);

  return (
    <>
      {
        pageData ? (
          <section className={`content-section ${styles['vacancies-page-content']} faq-page-content`}>
            <h1>{pageData?.custom_fields?.vacancy_page_title}</h1>

            <div className="faq-row-wrapper">
              <section className="faq-body" role="list">
                {careerData && (
                  careerData.map(item => {
                    return (
                      <Accordion
                        key={item.title}
                        title={item.title}
                        content={item.content}
                        cb={popupHandle}
                      />
                    )
                  })
                )}
              </section>
            </div>
            <div className={styles['vacancy-bottom-wrapper']}>
              <p className={styles['vacancy-bottom-text']}>{pageData?.custom_fields?.vacancy_bottom_text}</p>
              <a className={styles['vacancy-apply-button']} onClick={() => popupHandle('vacancy')}>Apply Now</a>
            </div>
            {isPopupOpen && <PopupForm isOpen={isPopupOpen} formType={formType} popupHandle={popupHandle} vacancy={vacancy.current} />}
          </section>) : (
          <p className='loading'></p>
        )
      }
    </>
  );
};

export default Career;
