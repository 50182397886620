import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useSmoothScroll = () => {
  const location = useLocation();
  const lastHash = useRef('');
  const navigate = useNavigate();

  const initScroll = () => {
    setTimeout(() => {
      document
        .getElementById(lastHash.current)
        ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      lastHash.current = '';
    }, 500);
  }

  useEffect(() => {
    lastHash.current = location.hash.slice(1);
    const path = location.pathname;
    if (location.pathname && !location.hash) {
      let cleanedPath = path.replace(/^\/|\/$/g, '');
      cleanedPath === '' ? cleanedPath = 'home' : cleanedPath;
      document.body.setAttribute('data-current-page', cleanedPath);
      document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (location.hash && document.querySelector(location.hash)) {
      initScroll();
    } else if (location.pathname && location.hash) {
      setTimeout(() => {
        navigate(`/${location.hash}`);
      }, 100);
      initScroll();
    }
  }, [location]);

  return null;
};

export default useSmoothScroll;
