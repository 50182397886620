import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Nav_icon from '../../assets/images/nav-icon.svg';
import PPC_agency from '../../assets/images/ppc_agency.svg';
import UTM_social from '../../assets/images/utm_social.svg';

import styles from '../../pages/Home/Home.module.css';

const MarketingSection = ({ page }) => {
  return (
    <section id="marketing" className={styles['marketing-section']}>
      <h2>{page.custom_fields.marketing_title}</h2>
      <div className={styles['marketing-images-block']}>
        <img src={UTM_social} alt="utm social" />
        <img src={PPC_agency} alt="ppc agency" />
      </div>
      <div className={styles['career-wrapper']}>
        <div className={styles['career']}>
          <h3>{page.custom_fields.career_title}</h3>
          <img src={Nav_icon} alt="icon nav" className={styles['icon-nav']} />
          <div className={styles['career-container']}>
            <p dangerouslySetInnerHTML={{ __html: page.custom_fields.career_text }}></p>
            <ul className={styles['career-tag-list']} dangerouslySetInnerHTML={{ __html: page.custom_fields.career_tags }}>
            </ul>
          </div>
          <Link to={page.custom_fields.career_btn_url} type='button' className={`${styles['see-vacancies-btn']} button`}><span>{page.custom_fields.career_btn_text}</span></Link>
        </div>
      </div>
    </section>
  )
}

MarketingSection.propTypes = {
  page: PropTypes.object
}

export default MarketingSection