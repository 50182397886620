import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const VacancyForm = ({ styles, onClose, errors, handleChange, submitInit, sended, vacancyName, loader, values }) => {
  const [fileName, setFileName] = useState('No file chosen');
  const [localValues, setLocalValues] = useState({ ...values });

  const handleFileChange = (event) => {
    event.target.files[0]?.name ? setFileName(event.target.files[0].name) : null;
  }

  useEffect(() => {
    if (values && Object.values(values).some(value => value !== '')) {
      setLocalValues(values);
    }
  }, [values]);

  const handleKeyDown = (e) => {
    const charCode = e.which ? e.which : e.keyCode;

    if (
      (charCode >= 48 && charCode <= 57) || // Number
      charCode === 8 || // Backspace
      charCode === 46 || // Delete
      charCode === 9 || // Tab
      (charCode >= 37 && charCode <= 40)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');

    if (!/^\d+$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalValues({
      ...localValues,
      [name]: value,
    });
    handleChange(e);
  };

  return (
    <div className={`${styles['vacancy-form-block']} ${sended ? styles.sended : ''}`} style={{ display: loader ? 'none' : 'block' }}>
      <a className={styles.closeButton} onClick={onClose} />
      <h3 className={styles['popup-form-title']}>Fill the form</h3>
      <form className={styles['vacancy-form']} onSubmit={(e) => submitInit(e)}>
        <input type="hidden" name='vacancyName' value={vacancyName} />
        <div className={`${styles['form-field']} ${errors.firstName ? styles.invalid : ''} first-name-field`}>
          <label>First name*:</label>
          <input type="text" name='firstName' placeholder='What&apos; s your first name?' value={localValues.firstName || ''} onChange={handleInputChange} />
        </div>
        <div className={`${styles['form-field']} ${errors.email ? styles.invalid : ''} email-field`}>
          <label>Email*:</label>
          <input type="email" name='email' placeholder='Please drop your email.' value={localValues.email || ''} onChange={handleInputChange} />
        </div>
        <div className={`${styles['form-field']} ${errors.phone ? styles.invalid : ''} phone-field`}>
          <label>Phone:</label>
          <input type="number" name='phone' placeholder='+000000000000' value={localValues.phone || ''} onKeyDown={handleKeyDown}
            onPaste={handlePaste} onChange={handleInputChange} />
        </div>
        <div className={`${styles['form-field']} phone-field`}>
          <span className={styles.label}>Attach your resume:</span>
          <input type="file" name='file' id='file' className="file-input" accept=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleFileChange} hidden />
          <div className={styles['file-block-action']}>
            <label htmlFor="file">Load</label>
            <span className={styles['file-chosen-name']}>{fileName}</span>
          </div>
        </div>
        <button type="submit" className={`${styles['button']} ${styles['registration-btn']}`}>Registration</button>
      </form>
    </div>
  );
}

VacancyForm.propTypes = {
  styles: PropTypes.object,
  onClose: PropTypes.func,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  submitInit: PropTypes.func,
  sended: PropTypes.bool,
  loader: PropTypes.bool,
  vacancyName: PropTypes.string,
  values: PropTypes.object,
}

export default VacancyForm;