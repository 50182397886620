import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { SHARE_LINK_INSTAGRAM, SHARE_LINK_TWITTER, SHARE_LINK_LINKEDIN } from '../../utils/Constants';
import { instagramIcon, twitterIcon, linkedinIcon } from '../../utils/Svgs';

import styles from './SharePopup.module.css'

const SharePopup = ({ onClose, images, postUrl, postTitle }) => {
  const [copyText, setCopyText] = useState(false);
  const copyMsg = useRef();

  const handleCopy = () => {
    navigator.clipboard.writeText(postUrl).then(() => {
      setCopyText('Link copied!');
    }).catch(err => {
      setCopyText('Copy error');
      console.error('Copy error: ', err);
    });

    copyMsg.current = setTimeout(() => {
      setCopyText(false);
    }, 1000)
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <a className={styles.closeButton} onClick={onClose} />
        <h3 className={styles['popup-form-title']}>Share</h3>

        <img src={images} alt="post banner" className={styles['popup-post-images']} />
        <h4 className={styles['popup-post-title']}>{postTitle}</h4>
        <p className={styles['popup-social-title']}>Share via social media</p>
        <ul className={styles['popup-social-list']}>
          {/* <li><a href={SHARE_LINK_INSTAGRAM} target="_blank" rel="noreferrer">{instagramIcon()}</a></li> */}
          <li><a href={`${SHARE_LINK_TWITTER + postUrl}`} target="_blank" rel="noreferrer">{twitterIcon()}</a></li>
          <li><a href={`${SHARE_LINK_LINKEDIN + postUrl}&title=${postTitle}`} target="_blank" rel="noreferrer">{linkedinIcon()}</a></li>
        </ul>
        <div className={styles['copy-block']}>
          <label className={styles['popup-social-title']}>Share via URL</label>
          <p type="text" className={styles['copy-input']}>{postUrl}</p>
          <button className={styles['copy-button']} onClick={handleCopy} >Copy URL</button>
        </div>
        {copyText ? <p className={styles['copy-status-msg']} ref={copyMsg}>{copyText}</p> : null}
      </div>
    </div>
  );
}

SharePopup.propTypes = {
  onClose: PropTypes.func,
  images: PropTypes.string,
  postUrl: PropTypes.string,
  postTitle: PropTypes.string,
}

export default SharePopup