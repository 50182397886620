import { useState, useEffect, useRef } from 'react'

import { fetchPage, validateForm, sendEmail } from '../../utils/Utils';
import useFormValidation from '../../hooks/useFormValidation';

import styles from '../../pages/Home/Home.module.css';

const ContactSection = () => {
  const [contactData, setContactData] = useState([]);
  const [sended, setSended] = useState(false);
  const [loader, setLoader] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postsData = await fetchPage('contact-us-block');

        setContactData(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchData();
  }, []);

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
  };

  const {
    errors,
    handleChange,
    handleSubmit,
  } = useFormValidation(initialState, validateForm, 'contact');

  const submitForm = async (e) => {
    !sended ? setLoader(true) : setLoader(false);

    const formSendData = new FormData(e.target);

    const status = await sendEmail('contact', formSendData);

    formRef.current.reset();

    setSended(status);

  };

  return (
    <section id="contact" className={styles['contact-section']}>
      <h3 className={styles['contact-section-title']}>{contactData?.custom_fields?.contact_form_title}</h3>
      <form action="#" onSubmit={(e) => handleSubmit(e, submitForm)} ref={formRef}>
        <div className={styles['field-wrapper']}>
          <p className={`${styles['form-field']} ${errors.firstName ? styles.invalid : ''}`}>
            <label>{contactData?.custom_fields?.contact_form_first_name}</label>
            <input className='input-field' type="text" name='firstName' placeholder='What`s your first name?' onChange={handleChange} />
          </p>
          <p className={`${styles['form-field']} ${errors.lastName ? styles.invalid : ''}`}>
            <label>{contactData?.custom_fields?.contact_form_last_name}</label>
            <input className='input-field' type="text" name='lastName' placeholder='And your last name?' onChange={handleChange} />
          </p>
          <p className={`${styles['form-field']} ${errors.email ? styles.invalid : ''}`}>
            <label>{contactData?.custom_fields?.contact_form_email}</label>
            <input className='input-field' type="text" name='email' placeholder='Please drop your email. We won&apos;t flood it with spam!' onChange={handleChange} />
          </p>
          <p className={styles['form-field']}>
            <label>{contactData?.custom_fields?.contact_form_subject}</label>
            <input className='input-field' type="text" name='subject' placeholder='What will we discuss?' onChange={handleChange} />
          </p>
          <p className={styles['form-field']}>
            <label>{contactData?.custom_fields?.contact_form_content_title}</label>
            <input className='input-field' type="text" name='text' placeholder='Tell us about the project' onChange={handleChange} />
          </p>
        </div>
        <div className='action-block'>
          <button type='submit' className={`${styles['submit-contact-btn']} button`} disabled={loader && !sended}><span>{contactData?.custom_fields?.contact_form_submit_btn_name}</span></button>
          <div className='loading' style={{ display: loader && !sended ? 'block' : 'none' }}></div>
        </div>
      </form>
    </section>
  )
}

export default ContactSection