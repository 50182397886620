import { useState } from 'react'
import PropTypes from 'prop-types';

import PopupForm from '../../components/PopupForm/PopupForm';
import Reload_icon from '../../assets/images/reload-icon.svg';

import styles from '../../pages/Home/Home.module.css';

const AboutSection = ({ page }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupHandle = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  return (
    <>
      <section id="about" className={styles['about-section']}>
        <div className={styles['about-technology']}>
          <div className={styles['about-technology-text']}>
            <p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_text_1 }}></p>
          </div>
        </div>
        <div className={`${styles['about-technology']} ${styles['about-technology-custom']}`}>
          <div className={`${styles['about-technology-info']} ${styles['color-border']}`}>
            <div className={styles['about-technology-info-bg']}>
              <div className={styles['about-technology-info-text']}>
                <h4>{page.custom_fields.about_technology_title}</h4>
                <p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_technology_text }}></p>
              </div>
              <img src={page.custom_fields.about_technology_img_url} alt={page.custom_fields.about_technology_title} />
            </div>
          </div>
        </div>
        <div className={styles['about-team']}>
          <div className={styles['about-team-text']}><h4>Trust:</h4><p>Long-term partnerships built on trust</p></div>
          <div className={styles['about-team-info']}>
            <div className={styles['about-team-info-bg']}>
              <h4>{page.custom_fields.about_team_title}</h4><p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_team_text }}></p>
              <div className={styles['about-team-avatar-wrapper']}>
                <img src={Reload_icon} className={styles['about-team-icon-reload']} alt='reload-icon' />
                <img src={page.custom_fields.about_team_avatar} className={styles['about-team-avatar']} alt='avatar' />
              </div>
            </div>
          </div>
        </div>
        <h3 className={styles['about-bottom-title']}>{page.custom_fields.about_sub_title}</h3>
        <div className={styles['about-offers']}>
          <div className={styles['about-team-text']}><h4>{page.custom_fields.about_trust_title}</h4><p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_trust_text }}></p></div>
          <div className={styles['about-offers-text']}><div className={styles['about-offers-text-wrapper']}><h4>{page.custom_fields.about_offers_title}</h4><p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_offers_text }}></p></div><img src={page.custom_fields.about_offers_images_url} className={styles['about-network-icon']} alt='network icon' /></div>
          <div className={`${styles['about-offers-info']} ${styles['color-border']}`}><div className={styles['about-offers-info-bg']}><h4>{page.custom_fields.about_payout_title}</h4><p dangerouslySetInnerHTML={{ __html: page.custom_fields.about_payout_text }}></p><div className={styles['about-wave-icon']} /></div></div>
        </div>
        <div className={styles['about-our-mission']}>
          <div className={styles['about-mission-content']} dangerouslySetInnerHTML={{ __html: page.custom_fields.about_bottom_block_text }}></div>
          <button type='button' className={`${styles['join-forces-btn']} button`} onClick={popupHandle}><span>{page.custom_fields.about_bottom_btn_name}</span></button>
        </div>
      </section >
      {isPopupOpen && <PopupForm isOpen={isPopupOpen} formType={'register'} popupHandle={popupHandle} />}
    </>
  )
}

AboutSection.propTypes = {
  page: PropTypes.object
}

export default AboutSection