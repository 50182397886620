import { useState, useMemo, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';

const register = {
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
};

const vacancy = {
  firstName: '',
  email: '',
  phone: '',
}

const contact = {
  firstName: '',
  lastName: '',
  email: '',
}

const useFormValidation = (sended, validate, formType) => {
  const [values, setValues] = useState(formType === 'register' ? register : formType === 'vacancy' ? vacancy : contact);
  const [errors, setErrors] = useState({});
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (isFirstRender.current && !sended) {
      const existingDataString = formType === 'register' ? localStorage.getItem('userRegData') : formType === 'vacancy' ? localStorage.getItem('userVacancyData') : localStorage.getItem('userContactData');
      let existingData = {};

      if (existingDataString) {
        existingData = JSON.parse(existingDataString);
      }

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          if (key === 'password' || key === 'confirmPassword') {
            continue;
          }

          if (values[key] !== existingData[key]) {
            existingData[key] = values[key];
          }
        }
      }

      const updatedUserRegString = JSON.stringify(existingData);
      formType === 'register' ? localStorage.setItem('userRegData', updatedUserRegString) : formType === 'vacancy' ? localStorage.setItem('userVacancyData', updatedUserRegString) : localStorage.setItem('userContactData', updatedUserRegString);
    } else {
      isFirstRender.current = true;

      const existDataString = formType === 'register' ? localStorage.getItem('userRegData') : formType === 'vacancy' ? localStorage.getItem('userVacancyData') : localStorage.getItem('userContactData');
      let existingDataObj = {};

      if (existDataString) {
        existingDataObj = JSON.parse(existDataString);
        setValues((prevValues) => ({
          ...prevValues,
          ...existingDataObj,
        }));
      }
    }
  });

  const getValues = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => {
      return {
        ...prevValues,
        [name]: value,
      };
    });
  };

  const handleChange = useMemo(
    () => debounce(getValues, 300)
    , []);

  const handleSubmit = (e, callback) => {
    e.preventDefault();
    const validationErrors = validate(values, formType);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      callback(e);
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useFormValidation;
