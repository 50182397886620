import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';

import Post_banner from '../../../assets/images/post-banner.jpg'
import NewsSection from '../../../sections/Home/NewsSection';
import SharePopup from '../../SharePopup/SharePopup';

import { shareIcon } from '../../../utils/Svgs';
import { fetchPosts, getPostDate } from '../../../utils/Utils';

import styles from './PostItem.module.css';

const PostItem = () => {
  const [post, setPost] = useState([]);
  const [isActive, setSetActive] = useState(false);
  const location = useLocation();
  const { postId } = useParams();
  const postUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postsData = await fetchPosts({
          params: {
            include: postId
          }
        });
        setPost(postsData[0]);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchData();
  }, [postId]);

  const shareHandle = () => {
    setSetActive(!isActive)
  }

  const getImage = () => {
    const images = post.fimg_url ? post.fimg_url : Post_banner;
    return images
  };

  return (
    <>
      {post.id ? (
        <div className='post-container'>
          <article className={`${styles['post-item-wrapper']} post-item-wrapper`}>
            <header>
              <a className={styles['share-post']} onClick={shareHandle}>{shareIcon()}</a>
              <p className={styles['post-date']}>{getPostDate(post)}</p>
              <h1>{post.title.rendered}</h1>
            </header>
            <div className="post-item-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
          </article>
          <NewsSection />
          {isActive ? <SharePopup onClose={shareHandle} images={getImage()} postUrl={postUrl} postTitle={post.title.rendered} /> : null}
        </div>
      ) : <p className='loading'></p>}
    </>
  )
}

export default PostItem