import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Post_banner from '../../../assets/images/post-banner.jpg';

import { getPostDate } from '../../../utils/Utils';

import styles from './PostBlock.module.css';

const PostBlock = ({ post }) => {
  const getImage = () => {
    const images = post.fimg_url ? post.fimg_url : Post_banner;
    return images;
  };

  return (
    <>
      <div className={styles['post-item']}>
        {Post_banner && <img src={getImage()} alt={post.title.rendered} className={styles['post-images']} />}
        <h4 className={styles['post-title']}>{post.title.rendered}</h4>
        <p className={styles['post-date']}>{getPostDate(post)}</p>
        <p className={styles['post-text']} dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}></p>
        <Link to={`/news/${post.id}`} className={`${styles['learn-more']} button`}><span>Learn more</span></Link>
      </div>
    </>
  )
}

PostBlock.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostBlock;