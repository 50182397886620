import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

import Instagram from '../../assets/images/in-icon.svg';
import Linkedin from '../../assets/images/link-icon.svg';
import Twitter from '../../assets/images/tw-icon.svg';

import { footerLogo } from '../../utils/Svgs.jsx';

import styles from './Footer.module.css';

const Footer = ({ links }) => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles['footer-logo-wrapper']}>
          {footerLogo()}
        </div>
        <div className={styles['navigation-wrapper']}>
          <div className={styles['footer-links']}>
            <nav>
              <ul className={styles['footer-nav-list']}>
                {links ? links.map((link) => (
                  <li key={link.label}>
                    {link.href.startsWith('#') ? (
                      <Link to={link.href}>
                        {link.label}
                      </Link>
                    ) : (
                      <NavLink
                        to={link.href}
                        className={({ isActive, isPending }) =>
                          isPending ? 'pending' : isActive ? styles.active : ''
                        }
                      >
                        {link.label}
                      </NavLink>
                    )}
                  </li>
                )) : null}
              </ul>
            </nav>
            <nav>
              <ul className={styles['footer-nav-list']}>
                <li><Link to={'privacy-policy'} type='button'><span>Privacy policy</span></Link></li>
                <li><Link to={'terms-and-conditions'} type='button'><span>Terms and Conditions</span></Link></li>
              </ul>
              <p className={styles['do-not-sell']}><Link to={'do-not-sell-my-personal-information'} type='button'><span>Do not sell/share/limit my info</span></Link></p>
            </nav>
          </div>
          <div className={styles['copyright-wrapper']}>
            <p className={styles['copyright-text']}>©{currentYear} TOGETHERADS PTE. LTD.<br /> All rights reserved.</p>
            {/* <ul className={styles['footer-social-list']}>
              <li><a href="#"><img src={Instagram} alt="Instagram" /></a></li>
              <li><a href="#"><img src={Twitter} alt="Twitter" /></a></li>
              <li><a href="#"><img src={Linkedin} alt="Linkedin" /></a></li>
            </ul> */}
          </div>
        </div>
      </footer >
    </>
  );
};

Footer.propTypes = {
  links: PropTypes.array
}

export default Footer;
