import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';

import useSmoothScroll from '../../hooks/useSmoothScroll';

import styles from './Header.module.css';

const TopNavigation = ({ closeMenuCb, links }) => {
  useSmoothScroll();

  return (
    <>
      <nav>
        <ul className={styles['top-nav-list']}>
          {links ? links.map((link) => (
            <li key={link.label}>
              {link.href.startsWith('#') ? (
                <Link to={link.href} onClick={closeMenuCb}>
                  {link.label}
                </Link>
              ) : (
                <NavLink
                  to={link.href}
                  className={({ isActive, isPending }) =>
                    isPending ? 'pending' : isActive ? styles.active : ''
                  }
                  onClick={closeMenuCb}
                >
                  {link.label}
                </NavLink>
              )}
            </li>
          )) : null}
        </ul>
      </nav>
    </>
  )
}

TopNavigation.propTypes = {
  closeMenuCb: PropTypes.func,
  links: PropTypes.array
};

export default TopNavigation