import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './Accordion.module.css';

const Accordion = ({ title, content, cb }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setHeight('0px');
    }

    const handleClick = (event) => {
      cb(event.target.closest('.faq-row').getAttribute('data-vacancy-name'));
    }
    const elements = contentRef.current?.querySelector('.wp-element-button');
    elements.addEventListener('click', handleClick);

    return () => {
      elements.removeEventListener('click', handleClick);
    }
  }, [isOpen]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className={`${styles['faq-row']} faq-row`} role="listitem" data-vacancy-name={title}>
      <div className={`${styles['row-title']} closed row-title ${isOpen ? styles.open : ''}`} role="button" onClick={toggleAccordion}>
        <div className="row-title-text">{title}</div>
        <span className={styles['icon-wrapper']}></span>
      </div>
      <div className={`${styles['row-content']} ${isOpen ? styles.open : ''}`} ref={contentRef} style={{ height: height }} role="region" >
        <div className={styles['row-content-text']} dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </section>
  );
};

Accordion.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  cb: PropTypes.func
}

export default Accordion;
