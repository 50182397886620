import { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PostItem from './components/Blog/PostItem/PostItem';
import Cursor from './components/Cursor/Cursor';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Career from './pages/Career/Career';
import DontSell from './pages/DontSell/DontSell';
import Home from './pages/Home/Home';
import News from './pages/News/News';
import Page404 from './pages/NotFound/Page404';
import Policy from './pages/Policy/Policy';
import Terms from './pages/Terms/Terms';

import { fetchNav } from './utils/Utils';

function App () {
  const [links, setLinks] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navsData = await fetchNav();
        setLinks(navsData);

      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    }
    fetchData();
  }, []
  );

  return (
    <>
      <Router>
        <Suspense fallback={<div className='loading'></div>}>
          <Header links={links} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/career" element={<Career />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/:postId" element={<PostItem />} />
            <Route path="/privacy-policy" element={<Policy />} />
            <Route path="/terms-and-conditions" element={<Terms />} />
            <Route path="/do-not-sell-my-personal-information" element={<DontSell />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
          <Footer links={links} />
          <Cursor />
        </Suspense>
      </Router>
    </>
  );
}

export default App;
