import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const RegisterForm = ({ styles, onClose, errors, handleChange, submitInit, sended, loader, values }) => {
  const [localValues, setLocalValues] = useState({ ...values });

  useEffect(() => {
    if (values && Object.values(values).some(value => value !== '')) {
      setLocalValues(values);
    }
  }, [values]);

  const handleKeyDown = (e) => {
    const charCode = e.which ? e.which : e.keyCode;

    if (
      (charCode >= 48 && charCode <= 57) || // Number
      charCode === 8 || // Backspace
      charCode === 46 || // Delete
      charCode === 9 || // Tab
      (charCode >= 37 && charCode <= 40)
    ) {
      return;
    } else {
      e.preventDefault();
    }
  };

  const handlePaste = (e) => {
    const pasteData = (e.clipboardData || window.clipboardData).getData('text');

    if (!/^\d+$/.test(pasteData)) {
      e.preventDefault();
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setLocalValues({
      ...localValues,
      [name]: value,
    });
    handleChange(e);
  };

  return (
    <div className={`${styles['regform-block']} ${sended ? styles.sended : ''}`} style={{ display: loader ? 'none' : 'block' }}>
      <a className={styles.closeButton} onClick={onClose} />
      <h3 className={styles['popup-form-title']}>Registration</h3>
      <form className={styles.regform} onSubmit={(e) => submitInit(e)}>
        <div className={`${styles['form-field']} ${errors.email ? styles.invalid : ''} email-field`}>
          <label>Email*:</label>
          <input type="email" name='email' placeholder='Please drop your email.' value={localValues.email || ''} onChange={handleInputChange} />
        </div>
        {/* <div className={`${styles['form-field']} ${errors.password ? styles.invalid : ''} password-field`}>
          <label>Password*:</label>
          <input type="password" name='password' placeholder='enter your password' onChange={handleChange} />
        </div>
        <div className={`${styles['form-field']} ${errors.confirmPassword ? styles.invalid : ''} password-confirm-field`}>
          <label>Password confirmation*:</label>
          <input type="password" name='confirmPassword' placeholder='confirm your password' onChange={handleChange} />
        </div> */}
        <div className={`${styles['form-field']} ${errors.password ? styles.invalid : ''} comments-field`}>
          <label>Comments:</label>
          <textarea type="text" name='comments' placeholder='enter your comments' rows='1' />
        </div>
        <div className={`${styles['form-field']} ${errors.phone ? styles.invalid : ''} phone-field`}>
          <label>Phone:</label>
          <input type="number" name='phone' placeholder='+000000000000' value={localValues.phone || ''} onKeyDown={handleKeyDown}
            onPaste={handlePaste} onChange={handleInputChange} />
        </div>
        <button type="submit" className={`${styles['button']} ${styles['registration-btn']}`}>Registration</button>
        <div className={styles['reg-terms']}><p>By clicking the registration button, you agree to our <Link to={'terms-and-conditions'} type='button' onClick={onClose}><span>Terms of Use</span></Link> and <Link to={'privacy-policy'} type='button' onClick={onClose}><span>Privacy Policy</span></Link>. You consent to the collection, use, and sharing of your personal data as outlined in our Privacy Policy. You acknowledge that you have read, understood, and agree to abide by these terms and conditions.
          <Link to={'terms-and-conditions'} type='button' onClick={onClose}><span>Terms of Use</span></Link> and <Link to={'privacy-policy'} type='button' onClick={onClose}><span>Privacy Policy</span></Link></p></div>
      </form>
    </div>
  );
}

RegisterForm.propTypes = {
  styles: PropTypes.object,
  onClose: PropTypes.func,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  submitInit: PropTypes.func,
  sended: PropTypes.bool,
  loader: PropTypes.bool,
  values: PropTypes.object,
}

export default RegisterForm;