import { useEffect, useState } from 'react'

import AboutSection from '../../sections/Home/AboutSection';
import ContactSection from '../../sections/Home/ContactSection';
import FaqSection from '../../sections/Home/FaqSection';
import MarketingSection from '../../sections/Home/MarketingSection';
import NewsSection from '../../sections/Home/NewsSection';
import TopSection from '../../sections/Home/TopSection';

import { fetchPage } from '../../utils/Utils';

const Home = () => {
  const [page, setPageData] = useState(null);

  useEffect(() => {

    const getNavigation = async () => {
      try {
        const pageData = await fetchPage('home');
        setPageData(pageData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    getNavigation();
  }, []
  );
  return (
    <>
      <main>
        {page ? (
          <>
            <TopSection page={page} />
            <AboutSection page={page} />
            <MarketingSection page={page} />
            {/* <NewsSection /> */}
            <FaqSection />
            <ContactSection />
          </>
        ) : (
          <p className='loading'></p>
        )}
      </main>
    </>
  )
}

export default Home