import React, { useState } from 'react'
import PropTypes from 'prop-types';

import RegisterForm from '../../sections/Forms/RegisterForm';
import VacancyForm from '../../sections/Forms/VacancyForm';

import { validateRegForm, sendEmail } from '../../utils/Utils';
import useFormValidation from '../../hooks/useFormValidation';

import styles from './PopupFormReg.module.css'

const PopupForm = ({ formType, popupHandle, vacancy }) => {
  const [sended, setSended] = useState(false);
  const [loader, setLoader] = useState(false);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useFormValidation(sended, validateRegForm, formType);

  const submitForm = async (e) => {
    if (!sended) setLoader(true);
    const formData = new FormData(e.target);
    const status = await sendEmail(formType, formData);
    setSended(status);
    clearFormData();
  };

  const clearFormData = () => {
    formType === 'register' ? localStorage.removeItem('userRegData') : formType === 'vacancy' ? localStorage.removeItem('userVacancyData') : localStorage.removeItem('userContactData');
  }

  const submitInit = async (e) => {
    await handleSubmit(e, submitForm)
  }

  const formProps = {
    styles,
    errors,
    handleChange,
    submitInit,
    onClose: popupHandle,
    sended,
    loader,
    values,
  };

  return (
    <div className={styles.overlay} onClick={popupHandle}>
      <div className={styles.popup} onClick={(e) => e.stopPropagation()}>
        <div className='loading' style={{ display: loader && !sended ? 'block' : 'none' }}></div>
        {formType === 'vacancy' ? <VacancyForm vacancyName={vacancy} {...formProps} /> : <RegisterForm {...formProps} />}

        <div className={`${styles['success-block']} ${sended ? styles.sended : ''}`}>
          <h4 className={styles['success-block-msg']}>We&apos;ve sent you an email.<br />
            Check your inbox!</h4>
          <a className={`${styles['success-button']} button`} onClick={popupHandle}>Ok</a>
        </div>
      </div>
    </div>
  );
}

PopupForm.propTypes = {
  popupHandle: PropTypes.func,
  vacancy: PropTypes.string,
  formType: PropTypes.string.isRequired,
}

export default PopupForm