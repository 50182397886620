
export const MouseCursor = (lineOne, lineTwo) => {
  document.addEventListener('mousemove', function (e) {
    const xPos = e.clientX;
    const yPos = e.clientY;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const line1 = lineOne;
    const line2 = lineTwo;

    // screen corners
    let x1, y1, x2, y2;

    //cursor is near right or left border
    const isCloserToLeftOrRight = xPos < windowWidth / 4 || xPos > (3 * windowWidth) / 4;
    const isAboveCenter = yPos < windowHeight / 2;

    if (isCloserToLeftOrRight) {
      if (xPos < windowWidth / 2) {
        //If left side of screen, the lines go to the right
        x1 = windowWidth;
        y1 = isAboveCenter ? windowHeight : 0;
        x2 = windowWidth;
        y2 = isAboveCenter ? 0 : windowHeight;
      } else {
        //If right side of screen, the lines go to the left
        x1 = 0;
        y1 = isAboveCenter ? windowHeight : 0;
        x2 = 0;
        y2 = isAboveCenter ? 0 : windowHeight;
      }
    } else {
      //Cyrsor in the center, react to the vertical position
      if (isAboveCenter) {
        x1 = 0;
        y1 = windowHeight;
        x2 = windowWidth;
        y2 = windowHeight;
      } else {
        x1 = 0;
        y1 = 0;
        x2 = windowWidth;
        y2 = 0;
      }
    }

    // Calculate the length of the lines
    const length1 = Math.sqrt(Math.pow(x1 - xPos, 2) + Math.pow(y1 - yPos, 2));
    const length2 = Math.sqrt(Math.pow(x2 - xPos, 2) + Math.pow(y2 - yPos, 2));

    // Calculate the angle of the lines for rotation
    const angle1 = Math.atan2(y1 - yPos, x1 - xPos) * 180 / Math.PI;
    const angle2 = Math.atan2(y2 - yPos, x2 - xPos) * 180 / Math.PI;

    //Change the position, length and angle of the lines
    line1.style.width = `${length1}px`;
    line1.style.transform = `translate(${xPos}px, ${yPos}px) rotate(${angle1}deg)`;

    line2.style.width = `${length2}px`;
    line2.style.transform = `translate(${xPos}px, ${yPos}px) rotate(${angle2}deg)`;
  });
}