import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/logo.svg';
import TopNavigation from '../Header/TopNavigation';
import PopupForm from '../PopupForm/PopupForm'
import RegButton from '../RegButton/RegButton';

import styles from './Header.module.css';

const Header = ({ links }) => {
  const [isActive, setIsActive] = useState(false);
  const [isActiveLogin, setIsActiveLogin] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const menuRef = useRef(null);

  const menuHandler = () => {
    setIsActive(!isActive);
  }

  const popupHandle = () => {
    setIsPopupOpen(!isPopupOpen);
    if (isActive) menuHandler();
  };

  const loginHandler = (e) => {
    setIsActiveLogin(!isActiveLogin);
  };

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsActiveLogin(false);
    }
  };

  useEffect(() => {
    if (isActiveLogin) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isActiveLogin]);
  return (
    <>
      <header className={`${styles.header} ${isActive ? styles.open : styles.closed}`}>
        <Link to={'/'} type='button' className={styles['logo-btn']}><img src={Logo} alt='Logo' /></Link>
        <div className={styles['navigate-wrapper']}>
          <TopNavigation closeMenuCb={isActive ? menuHandler : null} links={links} />
          <div className={`${styles['login-block']} ${isActiveLogin ? styles.active : ''}`} ref={menuRef}>
            <a className={styles['login-btn']} onClick={loginHandler}>Log in</a>
            <ul className={styles['login-list']} >
              <li><a href="https://publisher.together-ads.com/login" target='_blank'>Login as publisher</a></li>
              <li><a href="https://partner.together-ads.com/login" target='_blank'>Login as advertiser</a></li>
            </ul>
          </div>
          <RegButton openPopup={popupHandle} />
        </div>
        <div className={`${styles['burger-icon']} ${isActive ? styles.open : styles.closed}`} onClick={menuHandler}>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header >
      {isPopupOpen && <PopupForm formType={'register'} popupHandle={popupHandle} />}
    </>
  );
};

Header.propTypes = {
  links: PropTypes.array
}

export default Header;
