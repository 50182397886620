import React from 'react'
import PropTypes from 'prop-types';

import styles from '../Header/Header.module.css'

const RegButton = ({ openPopup }) => {

  return (
    <>
      <button type="button" className={styles['reg-button']} onClick={openPopup}>
        <span>Sign in</span>
      </button>
    </>
  )
}

RegButton.propTypes = {
  openPopup: PropTypes.func
}

export default RegButton