import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Img_404 from '../../assets/images/404.svg';

import styles from './Page404.module.css';

const Page404 = () => {
  useEffect(() => {
    document.body.setAttribute('data-current-page', 'not-found');
  }, []);

  return (
    <>
      <div className={styles['page-404-wrapper']}>
        <h1 className="visually-hidden">404 - Page Not Found</h1>
        <img src={Img_404} alt="not found page" className={styles['page-404-banner']} />
        <Link to={'/'} className={`${styles['back-home']} button`}><span>Back To Home</span></Link>
      </div>
      <hr />
    </>
  );
};

export default Page404;