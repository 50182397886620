import { useState, useEffect } from 'react'

import { fetchPage } from '../../utils/Utils'

import styles from './Policy.module.css';

const Policy = () => {
  const [page, setPageData] = useState(null);

  useEffect(() => {
    const getNavigation = async () => {
      try {
        const pageData = await fetchPage('privacy-policy');
        setPageData(pageData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    getNavigation();
  }, []
  );

  return (
    <>
      {page ? (
        <div className={styles['page-content-wrapper']} dangerouslySetInnerHTML={{ __html: page?.content.rendered }}></div>
      ) : (
        <p className='loading'></p>
      )}
    </>
  )
}

export default Policy