
export const instagramIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
    >
      <title>in-icon-svg</title>
      <path
        id="Layer"
        className="s0"
        d="m10 4.8c-2.8 0-5.1 2.3-5.1 5.3 0 2.9 2.3 5.2 5.1 5.2 2.8 0 5.1-2.3 5.1-5.2 0-3-2.3-5.3-5.1-5.3z"
      />
      <path
        id="Layer"
        fillRule="evenodd"
        className="s0"
        d="m20 10.1c0 2.7 0 3.1-0.1 4.2 0 1.1-0.2 1.8-0.4 2.5-0.6 1.4-1.6 2.5-3 3-0.6 0.2-1.3 0.4-2.4 0.5-1 0-1.4 0-4.1 0-2.7 0-3.1 0-4.1 0-1.1-0.1-1.8-0.3-2.5-0.5q-1-0.4-1.7-1.2-0.8-0.8-1.2-1.8c-0.2-0.7-0.4-1.4-0.4-2.5-0.1-1.1-0.1-1.5-0.1-4.2 0-2.8 0-3.2 0.1-4.3 0-1.1 0.2-1.8 0.4-2.5q0.4-1 1.2-1.8 0.7-0.8 1.7-1.2c0.7-0.2 1.4-0.4 2.5-0.5 1 0 1.4 0 4.1 0 2.7 0 3.1 0 4.1 0 1.1 0.1 1.8 0.3 2.5 0.5q1 0.4 1.7 1.2 0.8 0.8 1.2 1.8c0.2 0.7 0.4 1.4 0.4 2.5 0.1 1.1 0.1 1.5 0.1 4.3zm-3.8 0c0-3.6-2.8-6.5-6.2-6.5-3.4 0-6.2 2.9-6.2 6.5 0 3.5 2.8 6.4 6.2 6.4 3.4 0 6.2-2.9 6.2-6.4zm1.3-6.4c0-0.7-0.5-1.2-1.2-1.2-0.6 0-1.2 0.5-1.2 1.2 0 0.7 0.6 1.3 1.2 1.3 0.7 0 1.2-0.6 1.2-1.3z"
      />
    </svg>
  );
};
export const twitterIcon = () => {
  return (
    <svg
      version="1.2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width="20"
      height="20"
    >
      <title>tw-icon-svg</title>
      <path
        id="Layer"
        className="s0"
        d="m20 3.8q-1.1 0.5-2.4 0.7c0.9-0.6 1.5-1.4 1.8-2.4q-1.2 0.8-2.6 1.1c-0.7-0.9-1.8-1.4-3-1.4-2.2 0-4.1 1.9-4.1 4.2q0 0.5 0.1 1c-3.4-0.2-6.4-1.8-8.4-4.4q-0.6 1-0.6 2.1c0 1.5 0.8 2.8 1.9 3.5-0.7 0-1.4-0.2-1.9-0.5q0 0 0 0.1c0 2 1.4 3.7 3.3 4.1q-0.5 0.1-1.1 0.1-0.4 0.1-0.8 0c0.6 1.6 2.1 2.9 3.9 2.9-1.4 1.1-3.2 1.8-5.1 1.8q-0.5 0-1-0.1c1.8 1.2 4 1.9 6.3 1.9 7.5 0 11.7-6.4 11.7-12q0-0.2-0.1-0.5 1.3-0.9 2.1-2.2z"
      />
    </svg>
  );
};
export const linkedinIcon = () => {
  return (
    <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
      <title>link-icon-svg</title>
      <path id="Layer" className="s0" d="m20 20.3v-7.5c0-3.7-0.8-6.5-5-6.5-2 0-3.3 1.1-3.9 2.2v-1.9h-4v13.7h4.1v-6.8c0-1.8 0.4-3.5 2.5-3.5 2.1 0 2.2 2.1 2.2 3.7v6.6z" />
      <path id="Layer" className="s0" d="m0.3 6.6h4.2v13.7h-4.2z" />
      <path id="Layer" className="s0" d="m2.4-0.2c-1.3 0-2.4 1.1-2.4 2.4 0 1.4 1.1 2.5 2.4 2.5 1.3 0 2.4-1.1 2.4-2.5 0-1.3-1.1-2.4-2.4-2.4z" />
    </svg>
  );
};

export const footerLogo = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M50 0C22.4332 0 0 22.4332 0 50C0 77.5668 22.4332 100 50 100C77.5668 100 100 77.5668 100 50C100 22.4332 77.5751 0 50 0ZM50 94.1781C25.6427 94.1781 5.83015 74.3656 5.83015 50.0083C5.83015 25.651 25.6427 5.83015 50 5.83015C74.3573 5.83015 94.1698 25.6427 94.1698 50C94.1698 74.3573 74.3573 94.1698 50 94.1698V94.1781Z" fill="#2D2E2F" />
      <path d="M52.6124 26.4555L42.4531 73.5445H51.3269L59.164 30.8343L67.0095 73.5445H75.8749L65.7489 26.4555H52.6124Z" fill="#2D2E2F" />
      <path d="M42.6356 32.2939H47.2218L48.474 26.4555H24.1251V32.2939H34.0023V73.5445H38.3148L42.6356 53.5412V32.2939Z" fill="#2D2E2F" />
    </svg>
  );
};

export const shareIcon = () => {
  return (
    <svg width='48' height='52' viewBox='0 0 48 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d="M33.5388 11.5113L14.1395 22.5269M33.5388 40.4864L14.1395 29.4708M44.621 3.06173C47.365 5.8107 47.365 10.2677 44.621 13.0166C41.877 15.7656 37.4281 15.7656 34.6841 13.0166C31.9401 10.2677 31.9401 5.8107 34.6841 3.06173C37.4281 0.312757 41.877 0.312758 44.621 3.06173ZM46.6755 43.9166C46.6999 47.8041 43.5738 50.9754 39.6933 50.9999C35.8128 51.0243 32.6472 47.8926 32.6228 44.005C32.5984 40.1175 35.7245 36.9462 39.605 36.9218C43.4855 36.8974 46.6511 40.029 46.6755 43.9166ZM12.9949 21.021C15.739 23.77 15.739 28.227 12.9949 30.9759C10.2509 33.7249 5.80202 33.7249 3.05801 30.9759C0.313998 28.227 0.313997 23.77 3.05801 21.021C5.80202 18.2721 10.2509 18.2721 12.9949 21.021Z" stroke="#1D1D1B" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export const loader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="200" height="200" style="shape-rendering: auto; display: block; background: rgba(255, 255, 255, 0);"><g data-idx="1"><g transform="matrix(1,0,0,1,0,0)" data-idx="2">
      <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="3" opacity="0.083334">

      </rect>
    </g><g transform="matrix(0.8660254037844387,0.49999999999999994,-0.49999999999999994,0.8660254037844387,31.698729810778058,-18.30127018922194)" data-idx="5">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="6" opacity="0.166667">

        </rect>
      </g><g transform="matrix(0.5000000000000001,0.8660254037844386,-0.8660254037844386,0.5000000000000001,68.30127018922192,-18.30127018922194)" data-idx="8">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="9" opacity="0.25">

        </rect>
      </g><g transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,100,0)" data-idx="11">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="12" opacity="0.333334">

        </rect>
      </g><g transform="matrix(-0.4999999999999998,0.8660254037844387,-0.8660254037844387,-0.4999999999999998,118.30127018922192,31.69872981077805)" data-idx="14">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="15" opacity="0.416667">

        </rect>
      </g><g transform="matrix(-0.8660254037844387,0.49999999999999994,-0.49999999999999994,-0.8660254037844387,118.30127018922194,68.30127018922194)" data-idx="17">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="18" opacity="0.5">

        </rect>
      </g><g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,100,100)" data-idx="20">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="21" opacity="0.583334">

        </rect>
      </g><g transform="matrix(-0.8660254037844386,-0.5000000000000001,0.5000000000000001,-0.8660254037844386,68.30127018922192,118.30127018922194)" data-idx="23">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="24" opacity="0.666667">

        </rect>
      </g><g transform="matrix(-0.5000000000000004,-0.8660254037844384,0.8660254037844384,-0.5000000000000004,31.698729810778104,118.30127018922194)" data-idx="26">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="27" opacity="0.75">

        </rect>
      </g><g transform="matrix(-1.8369701987210297e-16,-1,1,-1.8369701987210297e-16,7.105427357601002e-15,100)" data-idx="29">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="30" opacity="0.833334">

        </rect>
      </g><g transform="matrix(0.5000000000000001,-0.8660254037844386,0.8660254037844386,0.5000000000000001,-18.30127018922194,68.30127018922192)" data-idx="32">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="33" opacity="0.916667">

        </rect>
      </g><g transform="matrix(0.8660254037844384,-0.5000000000000004,0.5000000000000004,0.8660254037844384,-18.30127018922194,31.698729810778104)" data-idx="35">
        <rect fill="#dddf4b" height="12" width="6" ry="6" rx="3" y="24" x="47" data-idx="36" opacity="1">

        </rect>
      </g><g data-idx="38"></g></g></svg>
  );
};
