import { useRef, useState, useEffect } from 'react'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
// eslint-disable-next-line import/no-unresolved
import { Pagination, Navigation } from 'swiper/modules';
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';

import PostBlock from '../../components/Blog/PostBlock/PostBlock';
import Arrow from '../../assets/images/arrow.svg';

import { NUMBER_OF_POSTS_SLIDE } from '../../utils/Constants';
import { fetchPosts } from '../../utils/Utils';

import styles from '../../pages/Home/Home.module.css';

const NewsSection = () => {
  const swiperRef = useRef();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const postsData = await fetchPosts({
          params: {
            per_page: NUMBER_OF_POSTS_SLIDE,
            order: 'asc',
          }
        });
        setPosts(postsData);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <section id="news-list" className={styles['news-section']}>
      <h3 className={styles['news-section-title']}>News</h3>
      <Swiper
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        slidesPerView={3}
        spaceBetween={20}
        pagination={{
          type: 'progressbar',
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        navigation={false}
        modules={[Pagination, Navigation]}
        className={styles['news-post-list']}
      >
        {!posts.length && <p className={styles['no-post-date']}>Posts not Found</p>}
        {posts.map(post => {
          return <SwiperSlide key={post.title.rendered}><PostBlock post={post} /></SwiperSlide>
        })}
      </Swiper>
      <div onClick={() => swiperRef.current.slideNext()} className={styles['next-btn']}><img src={Arrow} alt='next slider' /></div>
      <div onClick={() => swiperRef.current.slidePrev()} className={styles['prev-btn']}><img src={Arrow} alt='prev slider' /></div>
    </section >
  )
}

export default NewsSection