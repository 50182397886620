import { useState } from 'react'
import PropTypes from 'prop-types';

import PopupForm from '../../components/PopupForm/PopupForm';
import Section1_bg from '../../assets/images/section_1_bg.webp';
import Section_1_mob_bg from '../../assets/images/section_1_mob_bg.webp';

import styles from '../../pages/Home/Home.module.css';

const TopSection = ({ page }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const popupHandle = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  return (
    <>
      <section id="home" className={styles['home-section']}>
        <h1 className="visually-hidden">TOGETHERADS PTE. LTD</h1>
        <h2>{page.custom_fields.home_title}</h2>
        <img className={styles['text-logo']} src={page.custom_fields.home_title_images_url} alt={page.custom_fields.home_title} />
        <button type='button' className={`${styles['become-partner-btn']} button active`} onClick={popupHandle}><span>{page.custom_fields.home_top_btn_name}</span></button>
        <picture className={styles['section-1-bg']} >
          <source media="(min-width:768px)" srcSet={Section1_bg} />
          <source media="(min-width:320px)" srcSet={Section_1_mob_bg} />
          <img src={Section1_bg} alt='TOGETHERADS PTE. LTD' />
        </picture>
        <div className={styles['about-block']}><p className={styles['scroll-text']}>Scroll down</p><h3>{page.custom_fields.about_title}</h3></div>
      </section>
      {isPopupOpen && <PopupForm isOpen={isPopupOpen} formType={'register'} popupHandle={popupHandle} />}
    </>
  )
}
TopSection.propTypes = {
  page: PropTypes.object
}
export default TopSection